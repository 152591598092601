var esElearning = function () {

    var initToggleThis = function () {
        $('.toggle-that').each(function () {
            if (!$(this).hasClass('has-togglethis')) {
                $(this).click(function () {
                    var next = $(this).next('.toggle-this');
                    if (next.hasClass('closed')) {
                        next.removeClass('closed');
                    } else {
                        next.addClass('closed');
                    }

                });
                $(this).addClass('has-togglethis');
            }
        });
    }
    var initSolutions = function () {
        $('.show-solution').each(function () {
            if (!$(this).hasClass('has-show')) {
                $(this).click(function () {
                    $('.solution').show();
                    $(this).hide();
                });
                $(this).addClass('has-show');
            }
        });
    }
    var initCountdown = function () {
        $('.show-countdown').each(function () {
            if (!$(this).hasClass('has-countdown')) {
                var that = $(this);



                var timer2 = $(that).html();
                var interval = setInterval(function () {

                    var timer = timer2.split(':');
                    //by parsing integer, I avoid all extra string processing
                    var minutes = parseInt(timer[0], 10);
                    var seconds = parseInt(timer[1], 10);
                    --seconds;
                    minutes = (seconds < 0) ? --minutes : minutes;
                    if (minutes < 0)
                        clearInterval(interval);
                    seconds = (seconds < 0) ? 59 : seconds;
                    seconds = (seconds < 10) ? '0' + seconds : seconds;
                    //minutes = (minutes < 10) ?  minutes : minutes;
                    $(that).html(minutes + ':' + seconds);
                    timer2 = minutes + ':' + seconds;
                }, 1000);




                $(this).addClass('has-countdown');
            }
        });
    }

    var initReadnext = function(){
        $(".readnext").click(function () {
            var nextstep = $(".lessonstep:not(.is-opened)").first();
            var end = nextstep.next(".lessonstep");
            var nr = nextstep.attr('data-nr');
            nextstep.css("display", "block").addClass("is-opened");
            console.log('#lessonstep-image' + nr);
            if($('#lessonstep-image' + nr)){
                $('.lessonstep-image, .lessonstep-init-image').hide();
                $('#lessonstep-image' + nr).fadeIn('fast');
            }
            if(end.length<1){
                $(this).css("display","none");
            }
        });
    }
    
    var initOpenSection = function(){
        $(".learnCardsTitle").click(function(){
            if ($(this).next('.learnCardsContent').is(':visible')){
                $(this).next('.learnCardsContent').slideUp('slow');
                $(this).children('.arrow').children('svg').css('transform','rotate(0deg)');
                setCookie($(this).closest('.learnCardsContainer').attr('id'),'closed');
            } else {
                $(this).next('.learnCardsContent').slideDown('slow');
                setCookie($(this).closest('.learnCardsContainer').attr('id'),'open');
                $(this).children('.arrow').children('svg').css('transform','rotate(90deg)');
            }
        });
    }
    
    function setCookie(cname, cvalue, minutes) {
        const d = new Date();
        d.setTime(d.getTime() + (minutes * 60 * 1000));
        let expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function checkCookie() {
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while(c.charAt(0) == ' '){
                c = c.substring(1);
            }
            if (c.indexOf('open') > 0){
                const cnt = c.length - 5;
                let contentId = c.substring(0,cnt);
                $('#'+contentId).children('.learnCardsContent').css('display','block');
                $('#'+contentId).children('.learnCardsTitle').children('.arrow').children('svg').css('transform','rotate(90deg)');
            }
        }
    }
    
    function listCookies() {
        var theCookies = document.cookie.split(';');
        var aString = '';
        for (var i = 1 ; i <= theCookies.length; i++) {
            aString += i + ' ' + theCookies[i-1] + "\n";
        }
        return aString;
    }
    
    return {
        init: function () {
            initToggleThis();
            initSolutions();
            initCountdown();
            initOpenSection();
            checkCookie();
        },
        readNext: function(){
            initReadnext();
        }
    }
}();