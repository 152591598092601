var tbGui = function() {
    var initMobMenu = function() {
        $('.mob-menu').each(function(){
            if(!$(this).hasClass('has-toggle')){
                $(this).click(function(){
                    if($(this).hasClass('open')){
                        $(this).removeClass('open');
                        $('.main-nav').slideUp();
                    } else {
                        $(this).addClass('open');
                        $('.main-nav').slideDown();
                    }
                    
                });
                $(this).addClass('has-toggle')
            }
        });
    }
    var initStartTiles = function() {
        $('.teasers.add-hoveraction .teaser').each(function(){
            if(!$(this).hasClass('has-hoveraction')){
                $(this).mouseover(function(){
                    var usergroup = $(this).attr('data-usergroup')
                    $('.hoveraction-target').each(function(){
                        var that = this;
                        if($(that).hasClass('ug-' + usergroup)){
                            if($(this).css('opacity') < 1){
                                $(that).css('opacity',1);
                            }
                        } else {
                            $(that).css('opacity',0.2);
                        }
                    });
                });
                $(this).mouseout(function(){
                    $('.hoveraction-target').css('opacity',1);
                });
                $(this).addClass('has-hoveraction')
            }
        });
    }
    
    var initToggle = function() {
        $('.tog').each(function(){
            if(!$(this).hasClass('has-toggle')){
                $(this).click(function(){
                    var state = 0;
                    if($(this).hasClass('open')){
                        $(this).removeClass('open');
                        state = 0;
                    } else {
                        $(this).addClass('open');
                        state = 1;
                    }
                    doToggle();
                    var togid = $(this).attr('data-togid');
                    if(togid){
                        $.ajax({
                            type: 'POST',
                            url: '/action/setsession',
                            data: '&var=' + togid + '&val=' + state,
                        });
                    }
                });
                $(this).addClass('has-toggle')
            }
        });
        $('.tog-target').each(function(){
            if(!$(this).hasClass('has-toggle')){
                $(this).click(function(){
                    if($(this).hasClass('open')){
                        $(this).removeClass('open');
                    } else {
                        $(this).addClass('open');
                    }
                    doToggle();
                });
                $(this).addClass('has-toggle')
            }
        });
        doToggle();
    }
    var doToggle = function() {
        $('.tog').each(function(){
            var icon = $(this).find('svg use');
            if($(this).hasClass('open')){
                $(this).next('.tog-container').slideDown();
                icon.attr('xlink:href', '/dist/gui.svg#arrow-down');
            } else {
                $(this).next('.tog-container').slideUp();
                icon.attr('xlink:href', '/dist/gui.svg#arrow-right');
            }
        });
        $('.tog-target').each(function(){
            var parent = $(this).attr('data-parent');
            var target = $(this).attr('data-target');
            if($(this).hasClass('open')){
                $(this).closest('.' + parent).next('.' + target).slideDown();
            } else {
                $(this).closest('.' + parent).next('.' + target).slideUp();
            }
        });
    }

    return {
        init: function() {
            initMobMenu();
            initToggle();
            initStartTiles();
        },
        doToggle: function(){
            doToggle();
        }
    }
}();