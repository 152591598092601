var fileList = [];
var esForms = function() {
    
    var handleForms = function () {

        var handleInput = function (el) {
            if (el.val() != "") {
                el.addClass('edited');
            } else {
                el.removeClass('edited');
            }
        }     
        
        /*test();*/
        
        var handleErrors = function(el) {
            if (el.hasClass('dvForm_missed')) {
                el.parent('.form-group').addClass('has-error');
            } else if (el.find('input').hasClass('dvForm_missed')) {
                el.parent('.form-group').addClass('has-error');
            }
        }

        $('body').on('keydown', '.dvgui-text', function (e) {
            handleInput($(this));
        });
        $('body').on('blur', '.dvgui-text, .dvgui-select', function (e) {
            handleInput($(this));
        });
        $('body').on('select', '.dvgui-select', function (e) {
            handleInput($(this));
        });
        $('.dvgui-text, .dvgui-select').each(function () {
            handleInput($(this));
        });
        $('.dvgui-radio label').on('click').each(function () {
            handleInput($(this));
        });
        /// init for errors
        $('.dvgui-text, .dvgui-select, .dvgui-checkbox, .dvgui-radio').each(function () {
            handleErrors($(this))
        });

        $('body').on('click', '.dvgui-checkbox > label, .dvgui-radio > label', function () {
            var the = $(this);
            var el = $(this).children('span:first-child');
            el.addClass('inc');
            var newone = el.clone(true);
            el.before(newone);
            $("." + el.attr("class") + ":last", the).remove();
        });

        $('.submit-enter').keyup(function (e) {
            var keycode;
            if (window.event)
                keycode = window.event.keyCode;
            else if (e)
                keycode = e.which;
            else
                return true;
            if (keycode === 13) {
                $(this).parents('form').submit();
                return false;
            } else {
                return true;
            }
        });

        $('.select-submit').each(function () {
            if (!$(this).hasClass('has-submit')) {
                $(this).change(function () {
                    var frm = $(this).parents('form').submit();
                });
                $(this).addClass('has-submit');
            }
        });

        $('.lang-select li').each(function () {
            if (!$(this).hasClass('has-action')) {
                $(this).click(function () {
                    var lng = $(this).attr('data-lang');
                    $('.lang-switch').removeClass('lang-active');
                    $('.lang-' + lng).addClass('lang-active');
                    $('.lang-select li').removeClass('selected');
                    $(this).addClass('selected');
                });
                $(this).addClass('has-action'); 
            }
        });
        $(".documentupload-delete").click(function () {
            console.log('delete');
            var container = $(this).parents('.documentupload-result');
            var removeitem = $(this).parent('.documentupload-item');
            if(container.hasClass('multi')){
                removeitem.next('input').remove();
            } else {
                container.find('input').val();
            }
            removeitem.remove();
        });
        
        $("#delete_img").click(function() {
            if(confirm('Eintrag löschen?')){

                $("#profileimg").children("img").attr("src", "/dist/loading.gif");
                $.ajax({
                    type: 'POST',
                    url: '/user/deleteProfilePicture',
                    success: function(content){
                        console.log(content);
                        if(content == "del"){
                            location.href = '/user/profile';
                        }
                    }
                });
            }
        });
        
        $('.add-emoji').each(function () {
            if (!$(this).hasClass('has-emoji')) {
                $(this).on('click', function(){
                    var bb = $(this).attr('data-code');
                    var target = $(this).attr('data-target');
                    $('#' + target).selection('insert', {text: ' ' + bb + ' ', mode: 'before'}).selection('replace', {text: ''});
                });
                $(this).addClass('has-emoji')
            }
        });
        $('.add-bbstyle').each(function () {
            if (!$(this).hasClass('has-bbstyle')) {
                $(this).on('click', function(){
                    var bbtype = $(this).attr('data-type');
                    var target = $(this).attr('data-target');
                    var sel = $('#' + target).selection();
                    switch(bbtype){
                        case 'quote':
                            $('#' + target).selection('insert', {text: '[quote]', mode: 'before'}).selection('replace', {text: sel}).selection('insert', {text: '[/quote]', mode: 'after'});
                        break;
                        case 'bold':
                            $('#' + target).selection('insert', {text: '[b]', mode: 'before'}).selection('replace', {text: sel}).selection('insert', {text: '[/b]', mode: 'after'});
                        break;
                        case 'url':
                            $('#' + target).selection('insert', {text: '[url]', mode: 'before'}).selection('replace', {text: sel}).selection('insert', {text: '[/url]', mode: 'after'});
                        break;
                    }
                });
                $(this).addClass('has-bbstyle')
            }
        });
                


        $('.dvgui-documentupload').each(function () {
            if (!$(this).hasClass('has-upload')) {

                $(this).fileupload({
                    add: function (e, data) {

                        var name = $(this).attr('data-name');
                        var resultcontainer = $('#' + name + '_documentupload_result');     
                        var uploadErrors = [];
                        var maxsize = 5242880; //5MB
                        var maxsize2 = 20971520; //20MB
                        $.each(data.files, function (index, file) {
                            var typarr = file.name.split('.');
                            var typ = typarr[typarr.length - 1];
                            if (typ != 'pdf' && typ != 'xls' && typ != 'xlsx' && typ != 'doc' && typ != 'docx' && typ != 'txt' && typ != 'ppt' && typ != 'pptx' && typ != 'wmv' && typ != 'mpeg' && typ != 'avi' && typ != 'mov' && typ != 'mp4' && typ != 'odt' && typ != 'jpg' && typ != 'jpeg' && typ != 'png') {
                                uploadErrors.push('Unzulässiges Fileformat.');
                            }
                            if ((typ == 'mp4' || typ == 'wmv' || typ == 'mpeg' || typ == 'avi' || typ == 'mov') && file.size > 0 && file.size > maxsize2) {
                                uploadErrors.push('Filegrösse überschritten.');
                            } else if (!(typ == 'mp4' || typ == 'wmv' || typ == 'mpeg' || typ == 'avi' || typ == 'mov') && file.size > 0 && file.size > maxsize) {
                                uploadErrors.push('Filegrösse überschritten.');
                            }
                        });
                        if (uploadErrors.length > 0) {
                            resultcontainer.append('<div class="note note-error mr">' + uploadErrors.join('<br>') + '</div>');
                        } else {
                            resultcontainer.append('<img class="loading mr" src="/dist/loading.gif" />');
                            data.submit();
                        }
                    },
                    autoUpload: false,
                    done: function (e, data) {
                        if (data.result != '' && data.result != 'undefined') {
                            var name = $(this).attr('data-name');
                            var ret = $(this).attr('data-name');
                            var res = data.result.split("|");
                            var resultcontainer = $('#' + name + '_documentupload_result');
                            resultcontainer.find('.loading').remove();
                            
                            if(res[0] == 'MULTI'){
                                resultcontainer.append('<div class="' + name + '_documentupload_result documentupload-item">' + res[1] + '<a class="documentupload-delete btn">x</a></div>');
                                resultcontainer.append('<input type="hidden" name="' + name + '[]" value="' + res[1] + '">');
                            } else {
                                resultcontainer.find('.documentupload-item').remove();
                                $('#' + name).val(res[0]);
                                if(res[2]) $('#' + name + "_type").val(res[2]);
                                if(res[3]) $('#' + name + "_size").val(res[3]);
                                if($('#' + name + "_name").val() == '' && res[1]){
                                    var newname = res[1].split('.').slice(0, -1).join('.');
                                    $('#' + name + "_name").val(newname);
                                }
                                resultcontainer.prepend('<div class="' + name + '_documentupload_result documentupload-item">' + res[0] + '<a class="documentupload-delete btn">x</a></div>');
                            }
                            handleForms();
                        }
                    }
                });
                $(this).addClass('has-upload');
            }
        });
        
        $('.dvgui-imageupload').each(function () {
           
            if (!$(this).hasClass('has-upload')) {
                
                $(this).fileupload({
                    add: function (e, data) {

                        var name = $(this).attr('data-name');
                        //resultCon = "r" + counterBeitrag;
                        var resultcontainer = $('#' + name + '_imageupload_result');
                        var uploadErrors = [];
                        var maxsize = 5242880; //5MB
                        $.each(data.files, function (index, file) {
//                            console.log(data);
                            var typarr = file.name.split('.');
                            var typ = typarr[typarr.length - 1];
                            if (typ != 'jpg' && typ != 'jpeg' && typ != 'png' && typ != 'svg' && typ != 'gif') {
                                uploadErrors.push('Unzulässiges Fileformat.');
                            }
                            if (file.size > 0 && file.size > maxsize) {
                                uploadErrors.push('Filegrösse überschritten.');

                            }
                        });
                        if (uploadErrors.length > 0) {
                            resultcontainer.append('<div class="note note-error mr">' + uploadErrors.join('<br>') + '</div>');
                        } else {
//                            resultcontainer.append('<img class="loading mr" src="/dist/loading.gif" />');
                            $("#profileimg").html('<img src="/dist/loading.gif" />');
                            data.submit();
                        }
                    },
                    autoUpload: false,
                    done: function (e, data) {
                        console.log(data.result);
                        if (data.result != '' && data.result != 'undefined') {
                            filename = data.result;
                            var name = $(this).attr('data-name');
                            var resultcontainer = $('#' + name + '_imageupload_result');

                            //$("#profileimg").children("img").attr("src", "/data/tb/users/mid/" + filename);
                            $("#profileimg").html('<img src="/data/tb/users/mid/' + filename + '" />');
                            resultcontainer.html("");

                            handleForms();
                        }
                    }
                });
                $(this).addClass('has-upload');
            }
                    });

        $('.dvgui-wysiwyg').each(function () {
            if (!$(this).hasClass('has-wysiwyg')) {
                $(this).trumbowyg({
                    lang: 'de',
                    svgPath: '/dist/trumbowyg-icons.svg',
                    removeformatPasted: true,
                    core: {},
                    btnsDef: {
                        // Customizables dropdowns
                        image: {
                            dropdown: ['insertImage', 'upload'],
                            ico: 'insertImage'
                        }
                    },
                    btns: [
                        ['viewHTML'],
                        ['undo', 'redo'],
                        ['formatting'],
                        ['strong', 'em', 'del', 'underline'],
                        ['link'],
                        ['image'],
                        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                        ['unorderedList', 'orderedList'],
                        ['foreColor', 'backColor'],
                        ['preformatted']
                    ],
                    plugins: {
                        // Add imagur parameters to upload plugin
                        upload: {
                            serverPath: '/maps/imageupload',
                            fileFieldName: 'mapimage',
                            urlPropertyName: 'file'
                        }
                    }
                });
                $(this).addClass('has-wysiwyg');
            }
        });
    }
    return {
        init: function () {
            console.log('init forms');
            handleForms();
        },
        forms: function () {
            handleForms();
        },
        
    }
}();
