var esTables = function() {
    var params = {
        'language': table_trans,
        'responsive': true,
        'pageLength': 30,
        'bStateSave': true,
        'lengthChange': true,
        'lengthMenu': [
            [10, 30, 50, 100, -1],
            [10 , 30, 50, 100, 'Alle'] 
        ]
    }

    var initTables= function(el) {
        var prm = params;
        if(initpage){
            switch(initpage){
                case 'classesstudents':
                    prm.columnDefs = [
                        { 'targets': [0,1,2], 'orderable': true, 'searchable': true }, 
                    ];
                    prm.order = [[1, 'asc']] ;
                break;
                case 'classesdocuments':
                case 'coursesdocuments':
                    prm.columnDefs = [
                        { 'targets': [0,1,2,3], 'orderable': true, 'searchable': true }, 
                        { 'targets': [5], 'orderable': false, 'searchable': false },
                        { 'targets': [4], 'orderData': [5] }, 
                    ];
                    prm.order = [[1, 'asc']] ;
                break;
                case 'generatorindex':
                    prm.columnDefs = [
                        { 'targets': [0,1,2], 'orderable': true, 'searchable': true }, 
                        { 'targets': [3], 'orderData': [4] }, 
                    ];
                    prm.order = [[0, 'asc']] ;
                break;
            }
            $('#' + initpage).DataTable(prm);
        }
    }
    return {
        init: function() {
            initTables();
        },
    }
}();