var esGenerator = function() {
    var keyuptimeout;
    
    var  initGenerator = function(){
        $('.add-sortable').each(function(){
            if(!$(this).hasClass('has-sortable')){
                $(this).sortable({
                    update: function( event, ui ) {
                        submitGenerator();
                    }
                });
                $(this).addClass('has-sortable');
            }
        });
        $('.add-remove-question').each(function(){
            if(!$(this).hasClass('has-remove-question')){
                $(this).click(function(){
                   $(this).parents('.generator').remove();
                   submitGenerator();
                });
                $(this).addClass('has-remove-question');
            }
        });
        $('.add-add-question').each(function(){
            if(!$(this).hasClass('has-add-question')){
                $(this).click(function(){
                   var parent = $(this).parents('.generator');
                   var id = $(this).attr('data-frageid');
                   var generator = $(this).attr('data-generator');
                   var points = $(this).attr('data-points');
                   $.ajax({
                        type: 'POST',
                        url: '/generator/saveaddquestion',
                        data: '&id=' + id + '&generator=' + generator + '&points=' + points,
                        success: function(content){
                            if(content == 'OK'){
                                parent.remove();
                            }
                        }
                    });
                });
                $(this).addClass('has-add-question');
            }
        });
        $('.add-points-input').each(function(){
            if(!$(this).hasClass('has-points-input')){
                $(this).on('keyup',function(){
                    window.clearTimeout(keyuptimeout);
                    keyuptimeout = window.setTimeout(function(){
                        submitGenerator();
                    }, 500);                
                });
                $(this).addClass('has-points-input');
            }
        });
    }
    var  submitGenerator = function(){
        var data = $('#generatorform').serialize();
        var target = $('#generatorform').attr('action');
        $.ajax({
            type: 'POST',
            url: target,
            data: data + '&ajax=1'
            
        });

    }
    return {
        init: function() {
            initGenerator();
        },
    }
}();