var tbHistory = function() {

    var map = null;
    var map_overlays = {}; 
    var map_markers = {}; 
    var minwidth = 740;
    var move_state = 0;
    var move_ctrl = '';
    
    var labelicon = L.icon({
        iconUrl: 'trans.png',
        iconSize:     [2, 2],
        iconAnchor:   [1, 1],
        popupAnchor:  [1, 1] 
    });

    var initHistory = function(){
        
        map = L.map('history-map', { 
            crs: L.CRS.Simple,
            zoomControl: false,
            zoom: 1,
        });
        // hide layers on zoom
        map.on('zoomstart', function() {
            $('.hm').css('opacity','0');
            $('.history-label').css('opacity','0');
        });
        // show layers on zoom end
        map.on('zoomend', function() {
            $('.hm').css('opacity','1');
            $('.history-label').css('opacity','1');
        });
        // correc timeline
        map.on('movestart', function() {
            move_state = 1;
            staticTimeline();
        });
        map.on('moveend', function() {
            staticTimeline();
            move_state = 0;
        });
        
        
        
        
        
        addLayerOptions();
        
        $('.zoom-in').each(function () {
            if(!$(this).hasClass('has-zoom')){
                $(this).addClass('has-zoom');
                $(this).on('click', function () {
                    map.setZoom(map.getZoom() + 1);
                });
            }
        });

        $('.zoom-out').each(function () {
            if(!$(this).hasClass('has-zoom')){
                $(this).addClass('has-zoom');
                $(this).on('click', function () {
                    map.setZoom(map.getZoom() - 1);
                });
            }
        });
        $('.expand, .compress').each(function () {
            if(!$(this).hasClass('has-fullscreen')){
                $(this).addClass('has-fullscreen');
                $(this).on('click', function () {
                    toggleFullscreen();
                });
            }
        });
    }

    var addLayerOptions = function(){
        $.each(layers, function(i, layer){
            var btn = $('<a id="history-check' + i + '" class="history-check" data-id="'+i+'"><i class="fas fa-square"></i>' + layer[0] + '</a>').click(function(){
                
                if($('#history-check' + i).hasClass('checked')){
                    // if layer is visible remove it
                    $('#history-check' + i).removeClass('checked').find('i').removeClass('fa-check-square').addClass('fa-square');
                } else {
                    // if layer isn't visible show it
                    $('#history-check' + i).addClass('checked').find('i').removeClass('fa-square').addClass('fa-check-square');
                }
                showLayers();
            });
            $('#history-nav').append(btn);
        });
    }
    
    var showLayers = function(){
        var top = 0;
        var margin = 40;
        var map_w = $('#history-map').width();
        // remove all layers
        $.each(map_overlays, function(key, imageLayer) {
            if(map.hasLayer(imageLayer)){
                map.removeLayer(imageLayer);
            }
        });
        // remove all labels
        $.each(map_markers, function(key, labelMarker) {
            if(map.hasLayer(labelMarker)){
                map.removeLayer(labelMarker);
            }
        });

        // add timeline
        var layer_h = Math.round(map_w/minwidth*11);
        var bounds = [[-layer_h,0], [0,map_w]];
        map_overlays.timeline = L.imageOverlay('/dist/history-jahreszahlen.png', bounds, {alt : 'Jahreszahlen', className : ' timeline'}).addTo(map);
        top = layer_h+margin;

        // add selected layers
        $('.history-check.checked').each(function(){
            var id = $(this).attr('data-id');
            if(id > 0){
                var layer = layers[id];
                
                map_markers[id] = new L.marker([-top+10, 5], { opacity: 0.01, icon: labelicon }).bindTooltip(layer[0], {permanent: true,  className: 'hl hl' + id, offset: [0, 0] }).addTo(map);
               
                var layer_h = Math.round(layer[3]/(layer[2]/map_w));
                var new_top = top+layer_h;
                var bounds = [[-new_top,0], [-top,map_w]];
                map_overlays[id] = L.imageOverlay(layer[1] , bounds, {alt:layer[0], className:' hm hm' + id}).addTo(map);
                top = new_top+margin;
            }
        });

        if($('#history').hasClass('fullscreen')){
            var newheight = $('#history').height()-$('#history-nav').height()-10;
            $('#history-map').height(newheight + 'px');
        } else {
            $('#history-map').height(top + 'px');
        }
        map.invalidateSize();
        var maxbounds = [[-top*2,0], [top,map_w]];
        console.log(maxbounds);
        map.setMaxBounds(maxbounds);
        map.fitBounds(maxbounds);
        /*map.setView([0,0],0);
        map.invalidateSize();*/
        
        /*map.fitBounds(maxbounds);*/
        /*map.setView([-top/2,map_w/2],0);*/
    }
    
    var toggleFullscreen = function(){
        var hist = $('#history');
        if(hist.hasClass('fullscreen')){
            hist.removeClass('fullscreen');
            $('.expand').css('display','none');
            $('.compress').css('display','inline-block');
        } else {
            hist.addClass('fullscreen');
            $('.expand').css('display','none');
            $('.compress').css('display','inline-block');
        }
        showLayers();
    }

    var staticTimeline = function(){
        if(move_state > 0){
            if($('.timeline').length){
                if($('.leaflet-map-pane').css('transform') !== move_ctrl){
                    move_ctrl = $('.leaflet-map-pane').css('transform');
                    var split1 = move_ctrl.split(',');
                    var char = 0 - parseInt(split1[5].replace(')',''));
                    var split2 = $('.timeline').css('transform').split(',');
                    var top = 0 - parseInt(split2[5].replace(')',''));

                    console.log('CHAR: ', char);

                    $('.timeline').css('transform','translate3d('+split2[4]+'px,'+char+'px,0px)').css('z-index','999');
                }
            }
            setTimeout(staticTimeline,50);
        }
    }
    /*


    var tltimeout;
    var ignoreFirst = true;
    
    var showTimeline = function(i){
        if(ignoreFirst !== true){
            tltimeout = setTimeout(function(){
                if(i === 'neu'){
                    $('.timeline').css('opacity','0');
                    showTimeline('first');
                }else{
                    showTimeline();
                }
            }, 100);
        }else{
            ignoreFirst = false;
        }
    }
    
    
    var addAll = function(){
        $('.history-check').each(function(i,check){
            if(!$('.hm'+$(this).attr('data-check')).length){
                addMap($(this).attr('data-check'));
                $('#history-check'+$(this).attr('data-check')).prepend('<i style="margin-right: 5px; color: white" class="fas fa-check"></i>');
            }
        });

    }
    var removeAll = function(){
        $('#history-map').height(0);
        map.remove();
        initHistory(0);
        imgol = [];
        counter = 0;
        if(fs === true){
            //fs = false;
            $('.compress').css('display','none');
            $('.expand').css('display','block');
            $('#history-map').css({
                'position':'relative',
                'width':'minwidthpx',
                'height':counter+'px'
             });
             $('#history-nav').css({
                'position':'relative',
                'width':'minwidthpx'
             });
        }
    }
    var createLabelIcon = function(labelClass,labelText){
      return L.divIcon({ 
        className: labelClass,
        html: labelText,
      });
    }

    var setSize = function(){
        console.log('COUNTER: ' + counter);
        if(counter > 400){
            $('#history-map').height(counter);
        }else{
            $('#history-map').height(400);
        }
        map.fitBounds([[0,0], [$('#history-map').height(),$('#history-map').width()]]);
        map.setMaxBounds([[-$('#history-map').height(),0], [$('#history-map').height(),$('#history-map').width()]]);

        correctPos();
        setLayerPosition();

        $('#history-map').focus();
        $(window).scrollTop(0);
    }
    var setLayerPosition = function(){
        var h = 0;
        var fh = 0;
        $('.leaflet-overlay-pane .hm').each(function(i,element){
            console.log('HEIGHT: ' + h);
            $(this).css('top', h+'px');
            if(i > 0){
                h += $(this).height()+ 50;
                $(this).css('top',h+'px');
            }else{
                fh = $(this).height() + 50;
                $(this).css('top',h+'px');
            }

        });

        var minus = h + fh;
        map.setMaxBounds([[-minus,0], [minus,$('#history-map').width()]]);

    }
    var labelPos = function(){
        var h = 0;
        var fh = 0;
        $('.history-label').remove();
        $('.leaflet-overlay-pane .hm').each(function(i,element){
            if(i > 0){
                L.marker(new L.LatLng(-h, 0), {icon:createLabelIcon("history-label", $(this).attr("alt"))}).addTo(map);
                h += $(this).height() + 50;
            }else{
                fh = $(this).height() + 40;
                L.marker(new L.LatLng(fh, 0), {icon:createLabelIcon("history-label",$(this).attr("alt"))}).addTo(map);
            }
        });
        if(fs === true){
            $('.history-label').css({
                "font-size":"30px",
                "padding-top":"25px"
            });
        }
    }
    
    var labeltop = [0,50,150,350,750];
    var setLabelPosition = function(){
        var zoom = map.getZoom();
        var helper = 0;
        $('.history-label').each(function(i,element){
            if(i > 1){
                helper++; 
                $(this).css('top','-'+labeltop[zoom] * helper+'px');
            }else if(i < 1){
                $(this).css('top',labeltop[zoom]+'px');
            }
        });
    }
    var correctPos = function(){
        $('.leaflet-map-pane').css('transform','translate3d(0px,0px,0px)');
        map.setView([$('.leaflet-overlay-pane .hm').height() + 50, 0], 0);        
    }
    var toggleFullscreen = function(){
        var hist = $('#history');
        if(hist.hasClass('fullscreen')){
            hist.removeClass('fullscreen');
            $('.expand').css('display','none');
            $('.compress').css('display','inline-block');
        } else {
            hist.addClass('fullscreen');
            $('.expand').css('display','none');
            $('.compress').css('display','inline-block');
        }
        //correctPos();
        //resizeView();
    }
    
    var resizeView = function(){
        var save_prev = [];
        $('#history-map').css('display','block');
        console.log(imgol)
        
        $.each( imgol, function( key, value ) {
            var split = imgol[key]['options']['className'].split(' ');
            var idchecker = split[2].replace('hm','');
            save_prev.push(idchecker);
        });
        
        counter = 0;
        imgol = [];
        $('#history-map').height(0);
        map.remove();
        
        initHistory(0);
        
        $.each( save_prev, function( key, value ) {
            addMap(value);
        });
            setLayerPosition();
            setLabelPosition();
            showTimeline('neu');

    }
    var doit;
    $(window).resizeView(function(){
       clearTimeout(doit);
       doit = setTimeout(resize, 250);
       $('#history-map').css('display','none');
       $('.compress').css('display','none');
    });
    
    var hold;
    var zoomOut;
    var moveOut;
    var faster;
    var movestart = function(dir){
        faster = 55;
        hold = true;
        move(dir);
    }
    var move = function(dir){
        map.doubleClickZoom.disable();
        clearTimeout(zoomOut);
        zoomOut = setTimeout(function(){ map.doubleClickZoom.enable(); }, 1000);
        var cord = map.getCenter();
        var lat = cord['lat'];
        var lng = cord['lng'];
        if(dir === 'up'){
            map.panTo(new L.LatLng(lat+5, lng));
            if(faster > 5){
                faster--;
            }
        }else if(dir === 'down'){
            map.panTo(new L.LatLng(lat-5, lng));
            if(faster > 5){
                faster--;
            }
        }else if(dir === 'right'){
            map.panTo(new L.LatLng(lat, lng+10));
        }else if(dir === 'left'){
            map.panTo(new L.LatLng(lat, lng-10));
        }
        if(hold === true){
            moveOut = setTimeout(function(){move(dir); }, faster);
        }
    }
    var stopmove = function(){
        clearTimeout(moveOut);
        hold = false;
        showTimeline('neu');
    }*/
    
    
    
    
    return {
        init: function() {
            initHistory();
        },
    }
}();