var tbCertification = function() {

    var  initReadnext = function(){
        $('.readnext').each(function(){
            if(!$(this).hasClass('has-readnext')){
                $(this).click(function(){
                    if($('.lessonstep').not('.show').length > 0){
                        $('.lessonstep').each(function(){
                            if(!$(this).hasClass('show')){
                                $(this).addClass('show');
                                var thisstep = $(this).index('.lessonstep') + 1;
                                if($('#lessonstep-image' + thisstep).length > 0){
                                    $('.lessonstep-image, .lessonstep-init-image').hide(0);
                                    $('#lessonstep-image' + thisstep).fadeIn('fast');
                                }
                                console.log('INDEX: ' + thisstep);
                                return false;
                            }
                        });
                    } else {
                        var href = $('#nextlesson').attr('href');
                        window.location.href = href;
                    }
                });
                $(this).addClass('has-readnext');
            }
        });
    }
    var  initExplanation = function(){
        $('.step-explanation-btn').each(function(){
            if(!$(this).hasClass('has-toggle')){
                $(this).click(function(){
                    var next = $(this).next();
                    if(next.hasClass('open')){
                        next.removeClass('open');
                    } else {
                        next.addClass('open');
                    }
                });
                $(this).addClass('has-toggle');
            }
        });
    }
    var  initRelevanceChange = function(){
        $('.relevance-change').each(function(){
            if(!$(this).hasClass('has-toggle')){
                $(this).click(function(){
                    var parnt = $(this).parent().parent();
                    var next = parnt.next();
                    var chkd = $(this).prop('checked')
                    if(chkd){
                        next.hide('fast');
                    } else {
                        next.show('fast');
                    }
                });
                $(this).addClass('has-toggle');
            }
        });
    }
    
    var bar_h = 300;
    var handle_h = 25;

    var  initRelevance = function(){
        $('.validation-relevance-bar').each(function(){
            if(!$(this).hasClass('has-resizable')){
                var that = $(this);
                $(that).resizable({
                    handles: 'n',
                    maxHeight: 325,
                    minHeight: 25,
                    /*maxWidth: 100,
                    minWidth: 50,*/
                    stop: function(){
                        $('.validation-relevance-bar').removeClass('current');
                        $(that).addClass('current');
                        recalcBars();
                    }
                });
                $(this).addClass('has-resizable');
            }
        });
    }
    var recalcBars = function(){
        var total_height = 0;
        var total_nr = 0;
        $('.validation-relevance-bar').each(function(){
            var temp_h = parseFloat($(this).height()-handle_h);
            if(temp_h > 0){
                total_height += temp_h;
                if(!$(this).hasClass('current')){
                    total_nr++;
                }
            }
        });
        var diff_height = total_height-bar_h;
        var minus = diff_height/total_nr;
        $('.validation-relevance-bar').each(function(){
            var temp_h = parseFloat($(this).height());
            if(!$(this).hasClass('current') && temp_h > 0+handle_h){
                if(temp_h-handle_h > minus){
                    var newhight = $(this).height()-minus;
                    $(this).height(newhight);                    
                } else {
                    $(this).height(0+handle_h);
                }
            }
        });
        var sum_perc = 0;
        $('.validation-relevance-bar').each(function(){
            var temp_h = parseFloat($(this).height());
            var perc = 100/bar_h*(temp_h-handle_h);
            $(this).find('.percent').html(Math.round(perc * 10) / 10 + '%');
            $(this).find('input').val(perc);
            $(this).css('top','auto');
            sum_perc += perc;
        });
        console.log('SUM ' + sum_perc);

    }
    return {
        init: function() {
            initReadnext();
            initExplanation();
            initRelevance();
            initRelevanceChange();
        },
    }
}();