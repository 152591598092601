var tbDownloads = function() {

    
    var initDownloads = function(neu){
        $('#dlsearch').each(function(){
            if(!$(this).hasClass('has-search')){
                $(this).on("keyup", function() {
                    if($(this).val() != ""){
                        $('.downloads').find('.tog').addClass('open');
                    } else {
                        $('.downloads').find('.tog').removeClass('open');;
                    }
                    tbGui.doToggle();
                    var value = $(this).val();
                    $(".downloads article").each(function(index) {
                        $row = $(this);
                        var id1 = $row.text().toLowerCase();
                        if (id1.indexOf(value.toLowerCase()) != -1) {
                            $row.show();
                        } else {
                            $row.hide();
                        }
                    });
                });
                $(this).addClass('has-search')
            }
        });
    }
    
    return {
        init: function() {
            initDownloads();
        }
    }
}();