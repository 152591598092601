var tbMaps = function() {

    var pulseIcon0 = L.icon({
        iconUrl: '/dist/marker00.gif',
        iconSize:     [50, 50],
        iconAnchor:   [25, 25],
    });
    var pulseIcon1 = L.icon({
        iconUrl: '/dist/marker01.gif',
        iconSize:     [50, 50],
        iconAnchor:   [25, 25],
    });
    var pulseIcon2 = L.icon({
        iconUrl: '/dist/marker02.gif',
        iconSize:     [50, 50],
        iconAnchor:   [25, 25],
    });
    var pulseIcon3 = L.icon({
        iconUrl: '/dist/marker03.gif',
        iconSize:     [50, 50],
        iconAnchor:   [25, 25],
    });
    var pulseIcon4 = L.icon({
        iconUrl: '/dist/marker04.gif',
        iconSize:     [50, 50],
        iconAnchor:   [25, 25],
    });
    var pulseIcon5 = L.icon({
        iconUrl: '/dist/marker05.gif',
        iconSize:     [50, 50],
        iconAnchor:   [25, 25],
    });
    var pulseIcon6 = L.icon({
        iconUrl: '/dist/marker06.gif',
        iconSize:     [50, 50],
        iconAnchor:   [25, 25],
    });
    var pulseIcon7 = L.icon({
        iconUrl: '/dist/marker07.gif',
        iconSize:     [50, 50],
        iconAnchor:   [25, 25],
    });
    var pulseIcon8 = L.icon({
        iconUrl: '/dist/marker08.gif',
        iconSize:     [50, 50],
        iconAnchor:   [25, 25],
    });
    var pulseIcon9 = L.icon({
        iconUrl: '/dist/marker09.gif',
        iconSize:     [50, 50],
        iconAnchor:   [25, 25],
    });
    var pulseIcon99 = L.icon({
        iconUrl: '/dist/marker99.gif',
        iconSize:     [50, 50],
        iconAnchor:   [25, 25],
    });
    var scale = 5;
    var map = null;

    var initMaps = function(el) {
        var map = L.map('maps-container', { 
            crs: L.CRS.Simple 
        });

        var bounds = [[mapdata.h/scale,0], [0,mapdata.w/scale]];
        var image = L.imageOverlay('/maps/' + mapdata.getter + '/trig/' + mapdata.trig + '/layer/' + mapdata.ly + '/lang/' + mapdata.lang, bounds).addTo(map);
        console.log('::: ' + mapdata.getter);
        map.setMaxBounds(bounds);
        map.fitBounds(bounds);
        map.setZoom(scale-3);

        var markergroup0 = [];
        var markergroup1 = [];
        var markergroup2 = [];
        var markergroup3 = [];
        var markergroup99 = [];

        if(editable === true){
            newMapPoi();
            for ( var i=0; i < markers.length; ++i ) {
                markergroup0.push(L.marker([markers[i].lat/scale, markers[i].lng/scale], {icon: eval('pulseIcon' + markers[i].icon),draggable:'true'}).on('click', L.bind(editMapPoi, 'null', markers[i].id)).on('dragend', L.bind(setMapPoi, 'null', markers[i].id)).bindTooltip('Level ' + markers[i].level + ' - ' + markers[i].id + ' - ' + markers[i].title, { offset: L.point(0, 25),direction:'center' }));
            }
        } else {
            toggleAdminPoi();
            for ( var i=0; i < markers.length; ++i ) {
                if(markers[i].ext_link != ''){
                    eval('markergroup' + markers[i].level).push(L.marker([markers[i].lat/scale, markers[i].lng/scale], {icon: eval('pulseIcon' + markers[i].icon)}).on('click', L.bind(openExternalLink, 'null', markers[i].ext_link)).bindTooltip(markers[i].title + ' <svg class="maps-tooltip-icon"><use xlink:href="/dist/gui.svg#external-link"></use></svg>', { offset: L.point(0, 25),direction:'center' }));            
                } else if(markers[i].download != ''){
                    eval('markergroup' + markers[i].level).push(L.marker([markers[i].lat/scale, markers[i].lng/scale], {icon: eval('pulseIcon' + markers[i].icon)}).on('click', L.bind(openExternalLink, 'null', markers[i].download)).bindTooltip(markers[i].title + ' <svg class="maps-tooltip-icon"><use xlink:href="/dist/gui.svg#download"></use></svg>', { offset: L.point(0, 25),direction:'center' }));            
                } else {
                    eval('markergroup' + markers[i].level).push(L.marker([markers[i].lat/scale, markers[i].lng/scale], {icon: eval('pulseIcon' + markers[i].icon)}).on('click', L.bind(showMapDetail, 'null', markers[i].title, markers[i].text)).bindTooltip(markers[i].title, { offset: L.point(0, 25),direction:'center' }));
                }
            }
        }
        var markerlevel0 = L.layerGroup(markergroup0).addTo(map);
        var markerlevel1 = L.layerGroup(markergroup1);
        var markerlevel2 = L.layerGroup(markergroup2);
        var markerlevel3 = L.layerGroup(markergroup3);
        var markerlevel99 = L.layerGroup(markergroup99);
        if(adminpoi > 1){
            map.addLayer(markerlevel99);
        }

        map.on('zoomend', function() {
            var currentZoom = map.getZoom();
            console.log("Zoom: " + currentZoom);
            if (currentZoom > 2){ map.addLayer(markerlevel1); } else { map.removeLayer(markerlevel1); }
            if (currentZoom > 3){ map.addLayer(markerlevel2); } else { map.removeLayer(markerlevel2); }
            if (currentZoom > 4){ map.addLayer(markerlevel3); } else { map.removeLayer(markerlevel3); }
        });
    }

    var showMapDetail = function(param1,param2){
        $('#maps-overlay-inner').html(param2)
        showOverlay();
    }
    var openExternalLink = function(param1){
        window.open(param1);
    }

    var showOverlay = function() {
        $('#maps-overlay').fadeTo('fast', 1);
        $('#maps-overlay-bg').height($(document).height());
        $('#maps-overlay-bg').fadeTo('fast', 0.9);
        $('#maps-overlay-bg').click(hideOverlay);
    }
    var hideOverlay = function() {
        $('#maps-overlay-bg').unbind('click');
        $('#maps-overlay').fadeOut('fast');
        $('#maps-overlay-bg').fadeOut('fast');
        $('#maps-overlay-inner').html('');
    }
    var moveMapTo = function(mp,param1,param2){
        mp.panTo(new L.LatLng(param1, param2));
    }
    var editMapPoi = function(param1){
        $.ajax({
            type: 'POST',
            url: '/maps/adminedit',
            data: '&id=' + param1,
            success: function(content) {
                $('#maps-overlay-inner').html(content)
                showOverlay();
                /*esForms.init();*/
                $('.submit-poi').unbind('click');
                $('.submit-poi').click(function(){
                    var serialized = $("#poiamdinform").serialize();
                    $.ajax({
                        type: 'POST',
                        url: '/maps/adminedit',
                        data: serialized,
                        success: function(content) {
                            if (content == 'SAVE OK'){
                                location.reload(true);
                            } else {
                                $('#maps-overlay-inner').html(content);
                            }
                        }
                    });
                });
                $('.delete-poi').unbind('click');
                $('.delete-poi').click(function(){
                    var serialized = $("#poiamdinform").serialize();
                    $.ajax({
                        type: 'POST',
                        url: '/maps/admindelete',
                        data: serialized,
                        success: function(content) {
                            if (content == 'DELETE OK'){
                                location.reload(true);
                            }
                        }
                    });
                });
            }
        });
    }
    var setMapPoi = function(param1,event){
        var marker = event.target.getLatLng();
        console.log("POINT: " + (marker.lat*scale) + " --- " + (marker.lng*scale));
        $.ajax({
            type: 'POST',
            url: '/maps/adminset',
            data: '&id=' + param1 + '&lat=' + (marker.lat*scale) + '&lng=' + (marker.lng*scale),
        });
    }
    var newMapPoi = function(){
        $('.maps-new-poi').unbind('click');
        $('.maps-new-poi').click(function(){
            $.ajax({
                type: 'POST',
                url: '/maps/adminnew',
                data: '&parent=' + mapdata.trig,
                success: function(content) {
                    if (content == 'NEW OK'){
                        location.reload(true);
                    }
                }
            });
        });
    }
    var toggleAdminPoi = function(){
        $('.maps-admin-poi').unbind('click');
        $('.maps-admin-poi').click(function(){
            console.log('click' + adminpoi);
            if(adminpoi > 1){
                map.removeLayer(markerlevel99);
                adminpoi = 1;
            } else {
                map.addLayer(markerlevel99);
                adminpoi = 2;
            }
            $.ajax({
                type: 'POST',
                url: '/maps/setsession',
                data: '&adminpoi=' + adminpoi,
            });
        });
    }
    return {
        init: function() {
            initMaps();
        },
    }
}();