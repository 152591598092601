var toolboxMain = function() {
    return {
        init: function() {
            tbGui.init();
            console.log('PAGE: ' + initpage);
            if(initpage){ 
                switch(initpage){
                    case 'mapsshow': 
                    case 'mapsadminshow': 
                        tbMaps.init();
                    break;
                    case 'mapsadminedit': 
                        esForms.init();
                    break;
                    case 'validationsteps': 
                        esForms.init();
                        tbCertification.init();
                    break;
                    case 'validationlearn': 
                        tbCertification.init();
                    break;
                    case 'history': 
                        tbHistory.init(1);
                    break;
                    case 'downloads': 
                        tbDownloads.init(1);
                        esForms.init(1);
                    break;
                    case 'downloadsedit': 
                        esForms.init(1);
                    break;
                    case 'elearningerrors': 
                    case 'elearningtraining': 
                        esElearning.init();
                    case 'elearningtesting': 
                        esForms.init(1);
                    break;
                    case 'elearningtestingteps': 
                        esElearning.init();
                    break;
                    case 'elearninglearn':
                        esElearning.readNext();
                    break;
                    case 'elearningtrainingteps': 
                    case 'elearningerrorsteps': 
                        esElearning.init();
                    break;
                    case 'classesstudents':
                    case 'classesdocuments':
                    case 'coursesdocuments':
                        esTables.init();
                        esForms.init(1);
                    break;
                    case 'classesoverview':
                        esForms.init();
                    break;
                    case 'themaneu':
                        esForms.init();
                    break;
                    case 'beitragneu' :
                        esForms.init();
                    break;
                    case 'generatorindex':
                        esTables.init();
                    break;
                    case 'generatornew':
                        esForms.init();
                    break;
                    case 'generatoredit':
                        esForms.init();
                        esGenerator.init();
                    break;
                    case 'generatoraddquestion':
                        esForms.init();
                        esGenerator.init();
                    break;
                    case 'userprofile':
                        esForms.init();
                    break;
                }   
            }
            dvf.initTooltip();
        }
    }
}();

$(function() {    
   toolboxMain.init(); 
});

function init(){
    toolboxMain.init();
}
